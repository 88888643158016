<template>
  <div
    class="my-10"
    :class="{
      'd-flex align-center': actionType !== 2 && windowWidth > 400,
      'd-flex flex-column': windowWidth <= 400,
    }"
  >
    <v-text-field
      v-if="actionType === 0 || actionType === 4 || actionType === 5"
      class="my-5 w400"
      v-model="actionAddress"
      :label="$t('labels.walletAddress')"
      hide-details="auto"
      outlined
      :error="actionAddress ? !isValidEthWallet(actionAddress) : false"
    />

    <v-select
      v-if="actionType === 0 && web3.network == 1"
      hide-details="auto"
      class="w300 my-5 ml-4"
      outlined
      v-model="isVoter"
      :items="[
        { text: $t('components.entitledToVote'), value: true },
        { text: $t('components.noVotingRights'), value: false },
      ]"
      :label="$t('labels.select')"
      single-line
    ></v-select>

    <v-combobox
      v-if="actionType === 1"
      class="my-5 w400"
      v-model="actionAddress"
      :items="participantsAdresses"
      :placeholder="$t('placeholder.walletAddress')"
      hide-details="auto"
      clearable
      outlined
    />

    <div v-if="actionType === 2" class="my-3">
      <div class="d-flex justify-end">
        <v-btn
          @click="createProposal"
          :disabled="!isSumCorrect"
          class="create-btn"
          outlined
          text
          >{{ getButtonText }}</v-btn
        >
      </div>

      <v-card
        class="mt-3"
        v-for="(percent, index) in participantsPercents"
        :key="index"
        elevation="2"
      >
        <v-card-text>
          <div class="d-flex align-center">
            <Avatar :name="participantsAdresses[index].toString()" :size="40" />
            <div class="ml-5">
              {{ $shortAddress(participantsAdresses[index], windowWidth) }}
            </div>

            <div
              class="ml-5"
              v-if="web3.network == 1 && participantsVote[index]"
            >
              <v-chip color="primary">{{ $t("global.voter") }}</v-chip>
            </div>

            <v-text-field
              class="w100 ml-auto"
              dense
              hide-details
              type="number"
              v-model="participantsPercents[index]"
              outlined
              suffix="%"
              :error="!isSumCorrect"
            />
          </div>
        </v-card-text>
      </v-card>
    </div>

    <v-text-field
      v-if="actionType === 3 || actionType === 4 || actionType === 5"
      class="my-5 w400"
      v-model="amount"
      :label="
        $t('labels.payoutAmount', {
          network: actionType === 3 || actionType === 5 ? 'MATIC' : 'ART',
        })
      "
      :placeholder="
        $t('placeholder.payoutIn', {
          network: actionType === 3 || actionType === 5 ? 'MATIC' : 'ART',
        })
      "
      hide-details="auto"
      outlined
      :error="!isValidEth(amount)"
    />

    <v-btn
      v-if="actionType !== 2"
      :disabled="!isValidEth(amount) || !isValidEthWallet(actionAddress)"
      @click="createProposal"
      class="create-btn"
      :class="{ 'ml-5': windowWidth > 400 }"
      outlined
      text
      >{{ getButtonText }}</v-btn
    >
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import WAValidator from "wallet-address-validator";
import Avatar from "../components/avatar";
import windowWidth from "@/mixins/windowWidth";

export default {
  mixins: [windowWidth],
  props: {
    actionType: {
      type: Number,
      default: 0,
    },
  },

  components: { Avatar },

  data() {
    return {
      actionAddress: "",
      isVoter: true,
      amount: 0.00001,
      participantsAdresses: [],
      participantsPercents: [],
      participantsVote: [],
    };
  },

  watch: {
    amount(value) {
      this.amount = value.replace(/,/g, ".");
    },
    "web3.active"(val) {
      if (val && (this.actionType === 1 || this.actionType === 2)) {
        this.getParticipants();
      }
    },
  },

  computed: {
    web3() {
      return this.$store.state.web3;
    },

    proprosals() {
      return this.$store.state.web3.proprosals;
    },

    getButtonText() {
      const { actionType } = this;

      switch (actionType) {
        case 0:
          return this.$t("buttons.addWallet");
        case 1:
          return this.$t("buttons.deleteTheWallet");
        default:
          return this.$t("buttons.addVote");
      }
    },

    isSumCorrect() {
      const { participantsPercents } = this;
      if (participantsPercents.length == 0) return true;
      const count = participantsPercents.reduce((a, b) => {
        return parseInt(a) + parseInt(b);
      });

      const info = {
        text: this.$t("alert.theSumMustBe100"),
        type: "error",
      };

      if (count > 100) {
        this.$emit("info", info);
        return false;
      }

      if (count < 100) {
        return false;
      }

      return true;
    },
  },

  methods: {
    isValidEthWallet(address) {
      return WAValidator.validate(address, "ETH");
    },

    isValidEth(eth) {
      const ethString = eth.toString();
      const match = ethString.match(
        /(?!^0*$)(?!^0*\.0*$)^\d{1,10}(\.\d{1,50})?$/gm
      );

      return match ? true : false;
    },

    toWei(eth) {
      return new BigNumber(eth).multipliedBy(10 ** 18);
    },

    async createProposal() {
      try {
        const result = await this.$web3Call(
          "beforeCreateProposal",
          this.actionType,
          this.actionAddress,
          this.participantsPercents.map(parseFloat),
          this.participantsAdresses,
          this.web3.account,
          this.toWei(this.amount).toString(10)
        );

        if (result[0]) {
          this.info = { text: result[1], type: "error" };
          this.$emit("info", this.info);
          return;
        }
        if (this.web3.network == 1) {
          const receipt = await this.$web3Send(
            "createProposal",
            {},
            this.actionType,
            this.actionAddress,
            this.participantsPercents.map(parseFloat),
            this.participantsAdresses,
            this.toWei(this.amount).toString(10),
            this.isVoter
          );
        } else {
          const receipt = await this.$web3Send(
            "createProposal",
            {},
            this.actionType,
            this.actionAddress,
            this.participantsPercents.map(parseFloat),
            this.participantsAdresses,
            this.toWei(this.amount).toString(10)
          );
        }

        this.info = {
          text: this.$t("alert.voteCreated"),
          type: "success",
        };

        this.$emit("info", this.info);

        setTimeout(() => {
          this.$store.commit("set", {
            changeSwitcher: this.$store.state.changeSwitcher + 1,
          });
        }, 1000);
      } catch (error) {
        console.error(error);
        this.info = {
          text: this.$t("alert.errorSomethingWentWrong"),
          type: "error",
        };
        this.$emit("info", this.info);
      }
    },

    async getParticipants() {
      if(this.web3.network == 0) return;
      const info = await this.$web3Call("percentagePayouts");
      //console.log(info);
      this.participantsAdresses = info.participantsAdresses;
      this.participantsPercents = info.percents.map(parseFloat);
      if (this.web3.network == 1) this.participantsVote = info._isVoter;
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    const { actionType } = this;

    if (actionType === 2 || actionType === 3) {
      this.actionAddress = "0x0000000000000000000000000000000000000000";
    }

    if (this.web3.active && (this.actionType === 1 || this.actionType === 2)) {
      this.getParticipants();
    }
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.w100 {
  max-width: 100px;
}

.w300 {
  max-width: 300px;
}

.w400 {
  max-width: 400px;
}

.create-btn {
  height: 56px !important;
  background: rgba(212, 175, 55, 0.87);
  color: white !important;
  border: transparent;
}

.theme--light.v-btn.v-btn--disabled {
  color: white !important;
}
</style>
