var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-10",class:{
    'd-flex align-center': _vm.actionType !== 2 && _vm.windowWidth > 400,
    'd-flex flex-column': _vm.windowWidth <= 400,
  }},[(_vm.actionType === 0 || _vm.actionType === 4 || _vm.actionType === 5)?_c('v-text-field',{staticClass:"my-5 w400",attrs:{"label":_vm.$t('labels.walletAddress'),"hide-details":"auto","outlined":"","error":_vm.actionAddress ? !_vm.isValidEthWallet(_vm.actionAddress) : false},model:{value:(_vm.actionAddress),callback:function ($$v) {_vm.actionAddress=$$v},expression:"actionAddress"}}):_vm._e(),(_vm.actionType === 0 && _vm.web3.network == 1)?_c('v-select',{staticClass:"w300 my-5 ml-4",attrs:{"hide-details":"auto","outlined":"","items":[
      { text: _vm.$t('components.entitledToVote'), value: true },
      { text: _vm.$t('components.noVotingRights'), value: false } ],"label":_vm.$t('labels.select'),"single-line":""},model:{value:(_vm.isVoter),callback:function ($$v) {_vm.isVoter=$$v},expression:"isVoter"}}):_vm._e(),(_vm.actionType === 1)?_c('v-combobox',{staticClass:"my-5 w400",attrs:{"items":_vm.participantsAdresses,"placeholder":_vm.$t('placeholder.walletAddress'),"hide-details":"auto","clearable":"","outlined":""},model:{value:(_vm.actionAddress),callback:function ($$v) {_vm.actionAddress=$$v},expression:"actionAddress"}}):_vm._e(),(_vm.actionType === 2)?_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"create-btn",attrs:{"disabled":!_vm.isSumCorrect,"outlined":"","text":""},on:{"click":_vm.createProposal}},[_vm._v(_vm._s(_vm.getButtonText))])],1),_vm._l((_vm.participantsPercents),function(percent,index){return _c('v-card',{key:index,staticClass:"mt-3",attrs:{"elevation":"2"}},[_c('v-card-text',[_c('div',{staticClass:"d-flex align-center"},[_c('Avatar',{attrs:{"name":_vm.participantsAdresses[index].toString(),"size":40}}),_c('div',{staticClass:"ml-5"},[_vm._v(" "+_vm._s(_vm.$shortAddress(_vm.participantsAdresses[index], _vm.windowWidth))+" ")]),(_vm.web3.network == 1 && _vm.participantsVote[index])?_c('div',{staticClass:"ml-5"},[_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.$t("global.voter")))])],1):_vm._e(),_c('v-text-field',{staticClass:"w100 ml-auto",attrs:{"dense":"","hide-details":"","type":"number","outlined":"","suffix":"%","error":!_vm.isSumCorrect},model:{value:(_vm.participantsPercents[index]),callback:function ($$v) {_vm.$set(_vm.participantsPercents, index, $$v)},expression:"participantsPercents[index]"}})],1)])],1)})],2):_vm._e(),(_vm.actionType === 3 || _vm.actionType === 4 || _vm.actionType === 5)?_c('v-text-field',{staticClass:"my-5 w400",attrs:{"label":_vm.$t('labels.payoutAmount', {
        network: _vm.actionType === 3 || _vm.actionType === 5 ? 'MATIC' : 'ART',
      }),"placeholder":_vm.$t('placeholder.payoutIn', {
        network: _vm.actionType === 3 || _vm.actionType === 5 ? 'MATIC' : 'ART',
      }),"hide-details":"auto","outlined":"","error":!_vm.isValidEth(_vm.amount)},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}):_vm._e(),(_vm.actionType !== 2)?_c('v-btn',{staticClass:"create-btn",class:{ 'ml-5': _vm.windowWidth > 400 },attrs:{"disabled":!_vm.isValidEth(_vm.amount) || !_vm.isValidEthWallet(_vm.actionAddress),"outlined":"","text":""},on:{"click":_vm.createProposal}},[_vm._v(_vm._s(_vm.getButtonText))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }