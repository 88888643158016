<template>
  <div v-if="show" class="alert">
    <v-alert elevation="9" :type="type">
      {{ text }}
    </v-alert>
  </div>
</template>

<script>
export default {
  props: ["data"],

  data() {
    return {
      show: false,
      type: "",
      text: "",
    };
  },

  watch: {
    data(data) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }

      this.text = data.text;
      this.type = data.type;
      this.show = true;

      this.timeout = setTimeout(() => {
        this.show = false;
        this.timeout = null;
      }, 4000);
    },
  },
};
</script>

<style type="scss">
.alert {
  display: flex;
  position: fixed;
  top: 20px;
  left: 0px;
  right: 0px;
  justify-content: center;
}
.alert > div {
  max-width: 60%;
}
</style>
