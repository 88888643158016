<template>
  <div>
    <div v-if="proprosals.length">
      <v-card class="mt-3" v-for="p in proprosals" :key="p.id" elevation="2">
        <v-card-text>
          <div v-if="windowWidth < 580" class="d-flex flex-column">
            <div class="status">
              {{ voteStatus(p) }}
            </div>
            <small class="text-uppercase">{{
              $t("components.endAtTimeToEnd", {
                timeToEnd: timeToEnd(p.endTime),
              })
            }}</small>
          </div>

          <div class="card-top d-flex mb-2">
            <div v-if="p.result === 0" class="status status-red">
              {{ getStatus(p.actionType, false) }}
            </div>
            <div v-if="p.result === 1" class="status status-green">
              {{ getStatus(p.actionType) }}
            </div>
            <div v-if="p.result === 2" class="status status-gold">
              {{ $t("status.during") }}
            </div>

            <votes-list :index="p.id" :mobile="windowWidth < 580" />

            <div v-if="windowWidth > 580" class="d-flex flex-column">
              <div class="status">
                {{ voteStatus(p) }}
              </div>
              <small class="text-uppercase">{{
                $t("components.endAtTimeToEnd", {
                  timeToEnd: timeToEnd(p.endTime),
                })
              }}</small>
            </div>
          </div>

          <div
            v-if="p.actionType == 3 || p.actionType == 5"
            class="d-flex my-4 align-center"
          >
            <h3>
              {{ $t("components.paymentProposalXEth", { x: toEth(p.amount) }) }}
            </h3>
          </div>

          <div v-if="p.actionType == 4" class="d-flex my-4 align-center">
            <h3>
              {{ $t("components.paymentProposalXArt", { x: toEth(p.amount) }) }}
            </h3>
          </div>

          <div
            v-if="p.actionType < 2 || p.actionType == 4 || p.actionType == 5"
            class="d-flex align-center"
          >
            <Avatar :name="p.id.toString()" :size="40" />
            <div class="ml-5">
              {{ $shortAddress(p.actionAddress, windowWidth) }}
            </div>
          </div>

          <div v-if="p.actionType == 2">
            <div
              v-for="(c, i) in p.addresses.length"
              :key="i"
              class="d-flex align-center mb-2"
            >
              <Avatar :name="p.addresses[i]" :size="40" />

              <div class="ml-5">
                {{ $shortAddress(p.addresses[i], windowWidth) }}
              </div>
              <v-chip color="#6200EE" outlined small class="ml-auto">
                {{ p.percents[i] }}%
              </v-chip>
            </div>
          </div>
        </v-card-text>

        <v-divider
          v-if="
            ((p.voters.length === participantsAmount ||
              isVoteExpired(p.endTime)) &&
              p.result == 2) ||
            idVoteButtonEnabled(p)
          "
        />

        <v-card-actions>
          <v-btn
            v-if="idVoteButtonEnabled(p)"
            @click="vote(p.id, 1)"
            text
            class="default-button green-btn"
          >
            {{ $t("buttons.yes") }}
          </v-btn>
          <v-btn
            v-if="idVoteButtonEnabled(p)"
            @click="vote(p.id, 0)"
            text
            class="default-button red-btn"
          >
            {{ $t("buttons.no") }}
          </v-btn>
          <v-btn
            v-if="idVoteButtonEnabled(p)"
            @click="vote(p.id, 2)"
            text
            class="default-button gold-btn"
          >
            {{ $t("buttons.resigns") }}
          </v-btn>

          <v-btn
            v-if="
              (p.voters.length === participantsAmount ||
                isVoteExpired(p.endTime)) &&
              p.result == 2
            "
            @click="finish(p.id)"
            class="ml-auto"
            text
          >
            {{ $t("buttons.finishVoting") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <div v-else class="text-center py-16">
      <h4>{{ $t("components.noNewChoices") }}</h4>
    </div>
  </div>
</template>
<script>
import BigNumber from "bignumber.js";
import moment from "moment";
import Avatar from "../components/avatar";
import VotesList from "../components/votesList";
import windowWidth from "@/mixins/windowWidth";

export default {
  mixins: [windowWidth],
  components: { Avatar, VotesList },

  props: {
    proprosals: {
      type: Array,
      default: () => [],
    },

    actionName: {
      type: String,
      default: "",
    },

    actionType: {
      type: Number,
    },
  },

  data() {
    return {
      info: {},
      participantsAmount: 0,
      voters: [],
      devoted: [],
    };
  },

  watch: {
    "web3.account"() {
      this.updateVoteStatus();
    },
    proprosals(val) {
      this.getParticipants();
      this.updateVoteStatus();
    },
    "web3.active"(val) {
      if (val) {
        this.getParticipants();
      }
    },
  },

  computed: {
    web3() {
      return this.$store.state.web3;
    },
  },

  methods: {
    updateVoteStatus() {
      this.devoted = [];
      this.proprosals.map(async (p) => {
        let voted = await this.$web3Call("statusOfProposal", p.id);
        let i = voted[0].findIndex((a) => a.toLowerCase() == this.web3.account);
        if (i != -1) {
          this.devoted.push(p.id);
        }
      });
    },
    idVoteButtonEnabled(p) {
      return (
        p.result == 2 && !this.devotedVoice(p) && !this.isVoteExpired(p.endTime)
      );
    },

    isVoteExpired(timestamp) {
      const todayDate = moment(new Date());
      const pastDate = moment(timestamp);

      return !todayDate.isBefore(pastDate);
    },

    toEth(wei) {
      return new BigNumber(wei).dividedBy(10 ** 18);
    },

    devotedVoice(item) {
      const { voters, id } = item;

      const vote = voters.find((element) => element === this.web3.account);
      const voted = this.devoted.findIndex((element) => element === id);

      return vote || voted != -1 ? true : false;
    },

    async getParticipants() {
      const info = await this.$web3Call("percentagePayouts");
      if (this.web3.network == 0)
        this.participantsAmount = info.participantsAdresses.length;
      else {
        this.participantsAmount = info._isVoter.filter((a) => a).length;
      }

      this.voters = info.participantsAdresses;
    },

    getStatus(actionType, success = true) {
      if (actionType > 3) {
        actionType = 3;
      }

      if (success) {
        const statusList = [
          this.$t("status.added"),
          this.$t("status.deleted"),
          this.$t("status.set"),
          this.$t("status.paidOut"),
        ];

        return statusList[actionType];
      }

      const statusList = [
        this.$t("status.notAdded"),
        this.$t("status.notDeleted"),
        this.$t("status.notSet"),
        this.$t("status.notPaidOut"),
      ];

      return statusList[actionType];
    },

    timeToEnd(timestamp) {
      return moment(timestamp).format("D/M/Y");
    },

    voteStatus(p) {
      const finished = this.$t("status.theVotingIsClosed");
      const expired = moment().isAfter(p.endTime);
      if (expired) {
        return finished;
      }

      switch (p.result) {
        case 0:
          return finished;
        case 1:
          return finished;
        case 2:
          return this.actionName;
      }
    },

    async finish(index) {
      try {
        const result = await this.$web3Call(
          "beforeFinishProposal",
          index,
          this.web3.account
        );

        if (result[0]) {
          this.info = { text: result[1], type: "error" };
          this.$emit("info", this.info);

          return;
        }

        await this.$web3Send("finishProposal", {}, index);

        this.info = {
          text: this.$t("alert.youVoted"),
          type: "success",
        };
        this.$emit("info", this.info);
        this.$store.commit("getProprosals", {
          actionType: this.actionType,
          count: 10,
        });
      } catch (error) {
        console.error(error);
      }
    },

    async vote(index, data) {
      try {
        const result = await this.$web3Call(
          "beforeVoteInProposal",
          index,
          this.web3.account
        );

        if (result[0]) {
          this.info = { text: result[1], type: "error" };
          this.$emit("info", this.info);
          this.devoted.push(parseFloat(index));
          return;
        }

        const receipt = await this.$web3Send("voteInProposal", {}, index, data);

        this.info = {
          text: this.$t("alert.youVoted"),
          type: "success",
        };
        this.$emit("info", this.info);
        this.devoted.push(parseFloat(index));
        setTimeout(() => {
          this.$store.commit("getProprosals", {
            actionType: this.actionType,
            count: 10,
          });
        }, 4000);

      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 580px) {
  .card-top {
    justify-content: space-between;
  }
}

.status {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  padding: 6px 0px 0px 0px;
}

small {
  padding: 0px 0px 0px 0px;
}

.purple-button {
  background: rgba(98, 0, 238, 0.08);
  color: #6200ee !important;
  border-color: #6200ee !important;
}

.default-button {
  color: white !important;
}

.green-btn {
  background: #adc247;
}

.red-btn {
  background: #ff0000;
}

.gold-btn {
  background: rgba(212, 175, 55, 0.87);
}

.status-green {
  color: #adc247;
}

.status-red {
  color: #ff0000;
}

.status-gold {
  color: rgba(212, 175, 55, 0.87);
}

h3 {
  font-weight: 400;
}
</style>
